.roo-calendar {
  margin-top: 10px;
  background-color: transparent !important;
  font-size: 1.12em;
  border: none !important;
}
.roo-calendar .react-datepicker__header {
  padding-top: 0.8em;
  background-color: transparent !important;
  border: none !important;
}
.roo-calendar .react-datepicker__header--custom .datepicker-header .btn-holder {
  width: 30px;
  display: flex;
  justify-content: inherit;
}
.roo-calendar .react-datepicker__header--custom .datepicker-header {
  margin: 22px;
  display: flex;
  justify-content: space-between;
}
.roo-calendar .react-datepicker__current-month {
  font-weight: 400;
}
.roo-calendar .react-datepicker__month {
  font-weight: 500;
}
.roo-calendar .react-datepicker__day-name,
.roo-calendar .react-datepicker__day {
  width: 35px;
  line-height: 35px;
  margin: 0.166em;
  border-radius: 25px;
  color: #0747a6;
  font-weight: 800;
  border-radius: 25px;
}
.roo-calendar .react-datepicker__day:focus {
  outline: none;
}
.roo-calendar .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}
.roo-calendar .react-datepicker__day:hover {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: #6381a9;
  color: white;
  border: none;
}
.roo-calendar .react-datepicker__day--highlighted {
  background-color: unset;
}
.roo-calendar .react-datepicker__day--highlighted::after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #0747a6;
  border-radius: 2.5px;
  z-index: 90;
  display: block;
  margin-left: 45%;
  margin-top: -30%;
}
.roo-calendar
  .react-datepicker__day--selected.react-datepicker__day--highlighted::after {
  background-color: white;
}
.roo-calendar .react-datepicker__day--disabled,
.roo-calendar .react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #a0c4f9;
}
.roo-calendar .react-datepicker__day--selected {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: #ff991f;
  color: white;
}
.roo-calendar .react-datepicker__day--selected:hover {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: #ff991f;
  color: white;
  border: none;
}
.roo-calendar .react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.roo-calendar .react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.roo-calendar .react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

@media screen and (min-width: 62em) {
  .roo-calendar .react-datepicker__day--selected {
    width: 50px;
    height: 50px;
  }

  .roo-calendar .react-datepicker__day:hover {
    width: 50px;
    height: 50px;
  }

  .roo-calendar .react-datepicker__day {
    width: 50px;
    line-height: 50px;
    margin: 0.166em;
  }

  .roo-calendar .react-datepicker__day--selected:hover {
    width: 50px;
    height: 50px;
  }

  .roo-calendar .react-datepicker__day-name,
  .roo-calendar .react-datepicker__day {
    width: 50px;
    line-height: 50px;
  }
}
